import LibraryLayout from '../../layouts/library'
import en from '../../img/icons/en.svg'
import ru from '../../img/icons/ru.svg'
import uz from '../../img/icons/uz.svg'
import MarkerIcon from '../../img/icons/MarkerIcon'
import MinusIcon from '../../img/icons/MinusIcon'
import PlusIcon from '../../img/icons/PlusIcon'
import DropDownIcon from '../../img/icons/DropDownIcon'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import GetContainer from '../../components/get-container'
import { useDispatch, useSelector } from 'react-redux'
import {
  decrement,
  increment,
  resetFontSize,
  setChapters,
  setLang,
  toggleAddInfo,
  toggleAllChapter,
  toggleChapter,
  toggleShowMarker
} from '../../store/slices/htmlSlice'
import { RootState } from '../../store'
import { useEffect, useState } from 'react'
import FeedbackModal from '../../components/feedback-modal'
import { useTranslation } from 'react-i18next'
import WithTooltip from '../../components/with-html/WithTooltip'
import Play from '../../img/icons/Play'

export const LibraryDetail = () => {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const { chapters, showAddInfo, showMarker, lang } = useSelector((state: RootState) => state.html)
  const [openFeedback, setOpenFeedback] = useState(false)
  const [chapterId, setChapterId] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [articleSlug, setArticleSlug] = useState('')
  //@ts-ignore
  useEffect(() => {
    return () => {
      dispatch(setChapters([]))
      dispatch(resetFontSize())
    }
  }, [dispatch])

  return (
    <LibraryLayout>
      <GetContainer
        url={'dashboard/user/articles/' + id}
        onSuccess={data => {
          if (articleSlug !== id) {
            dispatch(setChapters(data?.data?.chapters))
            setArticleSlug(String(id))
          }
        }}
      >
        {({ data, isLoading }) => (
          <div className='library'>
            <div className='library-head'>
              <h1 className='library__title section-title'>{data?.data?.name?.[lang]}</h1>
              <div className='library-head__wrap'>
                <div className='library-head__lang'>
                  {data?.data?.name?.uz && (
                    <button onClick={() => dispatch(setLang('uz'))} className={lang === 'uz' ? 'current' : ''}>
                      <img src={uz} alt='lang' />
                      <span>UZ</span>
                    </button>
                  )}
                  {data?.data?.name?.ru && (
                    <button onClick={() => dispatch(setLang('ru'))} className={lang === 'ru' ? 'current' : ''}>
                      <img src={ru} alt='lang' />
                      <span>RU</span>
                    </button>
                  )}
                  {data?.data?.name?.en && (
                    <button onClick={() => dispatch(setLang('en'))} className={lang === 'en' ? 'current' : ''}>
                      <img src={en} alt='lang' />
                      <span>EN</span>
                    </button>
                  )}
                </div>
                <button
                  className='library-head__btn'
                  onClick={() => {
                    if (showAddInfo && showMarker) {
                      dispatch(toggleAddInfo())
                      dispatch(toggleShowMarker())
                    } else {
                      dispatch(toggleAddInfo())
                    }
                  }}
                >
                  <span>{t('Additional Information')}</span>
                  <input
                    checked={showAddInfo}
                    style={{
                      borderRadius: '50%'
                    }}
                    // onChange={e => dispatch(toggleAddInfo())}
                    type='checkbox'
                    className='checkbox'
                  />
                </button>
                <button className='library-head__btn' onClick={() => dispatch(toggleShowMarker())}>
                  <span>{t('Marker')}</span>
                  <input
                    checked={showMarker}
                    style={{
                      borderRadius: '50%'
                    }}
                    // onChange={e => dispatch(toggleAddInfo())}
                    type='checkbox'
                    className='checkbox'
                  />
                </button>
                <button
                  className='library-head__btn'
                  onClick={() =>
                    dispatch(chapters?.some(el => !el.isOpen) ? toggleAllChapter(true) : toggleAllChapter(false))
                  }
                >
                  <span>{chapters?.some(el => !el.isOpen) ? t('Open text') : t('Close text')}</span>
                  <input
                    checked={!chapters?.some(el => !el.isOpen)}
                    style={{
                      borderRadius: '50%'
                    }}
                    // onChange={e => dispatch(toggleAddInfo())}
                    type='checkbox'
                    className='checkbox'
                  />
                </button>
                <div className='library-head__btn'>
                  <MinusIcon onClick={() => dispatch(decrement())} />
                  <span>{t('Tt')}</span>
                  <PlusIcon onClick={() => dispatch(increment())} />
                </div>
              </div>
            </div>
            {data?.data?.blocks?.length ? (
              <div className='library-btn'>
                <button
                  className='btn btn-grey'
                  onClick={() => navigate(`/detail/article/${data?.data?.slug}/${data?.data?.blocks?.[0]?.id}`)}
                >
                  <Play width={'1rem'} height={'1rem'} />
                  <span>{t('Qbank mini test')}</span>
                </button>
              </div>
            ) : (
              <div />
            )}
            <div className='library-accordion'>
              {!isLoading &&
                chapters?.map((chapter: any, index: number) => (
                  <div
                    id={chapter?.id}
                    className={`library-accordion__item ${
                      chapter.isOpen || Number(searchParams.get('chapter_id')) === chapter?.id ? 'active' : ''
                    }`}
                    key={chapter.id}
                  >
                    <div className='library-accordion__head' onClick={() => dispatch(toggleChapter(index))}>
                      <span>{chapter.title[lang]}</span>
                      <DropDownIcon />
                    </div>
                    <div className='library-accordion__content'>
                      <WithTooltip html={chapter?.description[lang]} />
                      <div style={{ width: '100%', position: 'relative', height: '3rem' }}>
                        <button
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            cursor: 'pointer',
                            marginTop: '1.8rem',
                            position: 'absolute',
                            right: '1.8rem',
                            zIndex: 80
                            //   bottom: '1rem'
                          }}
                          onClick={() => {
                            setOpenFeedback(true)
                            setChapterId(chapter?.id)
                          }}
                        >
                          <MarkerIcon width={'1rem'} height={'1rem'} />
                          {t('Feedback')}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <FeedbackModal
              type='chapter'
              article_slug={id}
              chapter_id={chapterId}
              zIndex={100}
              isVisible={openFeedback}
              hide={() => {
                setOpenFeedback(false)
              }}
            />
          </div>
        )}
      </GetContainer>
    </LibraryLayout>
  )
}
