import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setPhoto } from '../../store/slices/htmlSlice'
import DOMPurify from 'dompurify'
import '../../content.scss'

interface IProps {
  html: string
  isQuiz?: boolean
  openMarker?: boolean
}

function WithHtml(props: IProps) {
  const { fontSize, showMarker, showAddInfo } = useSelector((state: RootState) => state.html)
  var ptRegex = /(\d+(\.\d+)?)pt/g
  var pxRegex = /(\d+(\.\d+)?)px/g

  // Function to convert points to pixels
  function ptToRem(match: any, p1: any) {
    var ptSize = parseFloat(p1)
    var pxSize = (ptSize * 1.33333) / 16 // Approximate conversion
    return pxSize + 'em'
  }
  function pxToRem(match: any, p1: any) {
    var ptSize = parseFloat(p1)
    var pxSize = ptSize / 16 // Approximate conversion
    return pxSize + 'em'
  }
  const newHtml = useMemo(() => {
    return props?.html
      ?.replace(/<span>(.*?)<\/span>/g, '<p>$1</p>')
      ?.replace(ptRegex, ptToRem)
      ?.replace(pxRegex, pxToRem)
      ?.replaceAll('color: black;', '')
      ?.replaceAll('color: black;', '')
      ?.replaceAll('color: windowtext;', '')
      ?.replaceAll('color: rgb(0, 0, 0);', '')
      ?.replaceAll('background: white;', '')
      .replace(/<table/g, '<div id="tableWrapper"><table')
      .replace(/<\/table>/g, '</table></div>')
  }, [props?.html])

  const dispatch = useDispatch()

  useEffect(() => {
    const el = document.getElementsByTagName('u')
    const info = document.getElementById('info')
    //@ts-ignore
    for (const item of el) {
      if (props.isQuiz) {
        if (props.openMarker) {
          item.style.display = 'inline'
          item.style.textDecoration = 'none'
          item.style.borderBottom = '2px solid rgba(249, 194, 6, 1)'
        }
        if (props.isQuiz) {
          item.style.display = 'inline'
          item.style.border = 'none'
          item.style.textDecoration = 'none'
        }

        if (props.isQuiz && props.openMarker) {
          item.style.textDecoration = 'none'
          item.style.borderBottom = '2px solid rgba(249, 194, 6, 1)'
        }
      } else {
        if (!showAddInfo && !showMarker) {
          item.style.display = 'none'
        }

        if (showAddInfo) {
          item.style.display = 'inline'
          item.style.border = 'none'
          item.style.textDecoration = 'none'
        }
        if (showAddInfo && showMarker) {
          item.style.textDecoration = 'none'
          item.style.borderBottom = '2px solid rgba(249, 194, 6, 1)'
        }
        if (showMarker) {
          item.style.textDecoration = 'none'
          item.style.borderBottom = '2px solid rgba(249, 194, 6, 1)'
        }
      }
      item.style.width = 'auto'
    }

    if (!props.isQuiz) {
      if (showAddInfo) {
        info?.style.setProperty('display', 'block')
      } else {
        info?.style.setProperty('display', 'none')
        info?.style.setProperty('overflow', 'hidden')
      }
    }
    console.log('ishladi', props.isQuiz, showAddInfo, info)
  }, [dispatch, showAddInfo, showMarker, props.isQuiz, props.openMarker])

  const openImage = (url: string) => {
    dispatch(
      setPhoto({
        type: 'image',
        url: url
      })
    )
  }

  useEffect(() => {
    if (!!newHtml) {
      const childs = document.getElementById('dangerous')
      const aTags = document?.getElementsByTagName('a')
      if (aTags?.length) {
        //@ts-ignore
        for (const item of aTags) {
          const href = item?.href?.split('/')
          const isNote = href?.some((e: string) => e === 'article_note_text')
          const isPhoto = href?.some((e: string) => e === 'article_note_photos')
          if (isNote) {
            item.setAttribute('data-some-relevant-attr', '/dashboard' + item?.href?.split('dashboard')[1])
            item.setAttribute('data-tooltip-id', 'my-tooltip')
            item.removeAttribute('href')
          } else if (isPhoto) {
            item?.setAttribute('photo-url', '/dashboard' + item?.href?.split('dashboard')[1])
            item?.addEventListener('click', () => openImage(item?.getAttribute('photo-url')))
            item.style.color = 'red'
            item.removeAttribute('href')
            item.style.cursor = 'pointer'
          }
        }
      }
    }
  }, [newHtml])

  return (
    <>
      <div
        className='dangerous'
        id='dangerous'
        style={{
          fontSize: fontSize
        }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newHtml) }}
      ></div>
    </>
  )
}

export default WithHtml
