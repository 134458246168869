import { useContext, useEffect } from 'react'
import MessagesIcon from '../../img/icons/MessagesIcon'
import SettingsIcon from '../../img/icons/SettingsIcon'
import TariffsIcon from '../../img/icons/TariffsIcon'
import WalletIcon from '../../img/icons/WalletIcon'
import { MainLayout } from '../../layouts/main'
import Payments from './components/Payments'
import Settings from './components/Settings'
import Tariffs from './components/Tariffs'
import Messages from './components/Messages'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL } from '../../helpers/request'
import { AuthContext } from '../../providers/auth-provider'
// import acc from '../../img/acc.svg'

export const Account = () => {
  const { t, i18n } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (!searchParams.has('type')) {
      setSearchParams({
        type: 'payments'
      })
    }
  }, [searchParams, setSearchParams])
  const { user } = useContext(AuthContext)
  return (
    <MainLayout>
      <section className='account'>
        <h1 className='account__title section-title'>{t('Personal cabinet')}</h1>
        <div className='account-content'>
          <div className='account-side'>
            <div className='account-side__item'>
              <div className='account-side__name'>{t(user?.profession)}</div>
              <div className='account-side__value'>
                <img src={user?.image ? MEDIA_URL + user?.image : '/image.png'} alt='user' />
                <span>{user?.firstname + ' ' + user?.lastname}</span>
              </div>
            </div>
            <div className='account-side__item'>
              <div className='account-side__name'>
                {t('user id:')} {user?.wallet?.user_id}
              </div>
              <div className='account-side__value'>
                {user?.wallet?.amount || '0'} {t('sum')}
              </div>
            </div>
            <div className='account-side__item'>
              <div className='account-side__name'>{t('Tariff')}</div>
              {user?.tariff?.length ? (
                user?.tariff?.map((tariff: any) => (
                  <div key={tariff.id} className='account-side__value'>
                    {tariff?.name?.[i18n.language]} ({tariff?.term_id?.name?.[i18n.language]})
                  </div>
                ))
              ) : (
                <div className='account-side__value'>{t('Active tariff not found')}</div>
              )}
            </div>
            <div className='account-side__item'>
              <div className='account-side__name'>{t('Sex')}</div>
              <div className='account-side__value'>{t(user?.gender)}</div>
            </div>

            <div className='account-side__item'>
              <div className='account-side__name'>{t('Phone number')}</div>
              <div className='account-side__value'>+{user?.phone}</div>
            </div>
            <div className='account-side__item'>
              <div className='account-side__name'>{t('Email:')}</div>
              <div className='account-side__value'>
                <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{user?.email}</p>
              </div>
            </div>
          </div>
          <div className='account-main'>
            <ul className='account-head'>
              <li
                className={searchParams.get('type') === 'payments' ? 'current' : ''}
                onClick={() =>
                  setSearchParams({
                    type: 'payments'
                  })
                }
              >
                <WalletIcon />
                <span>{t('Payments')}</span>
              </li>
              <li
                className={searchParams.get('type') === 'tariffs' ? 'current' : ''}
                onClick={() =>
                  setSearchParams({
                    type: 'tariffs'
                  })
                }
              >
                <TariffsIcon />
                <span>{t('Tariffs')}</span>
              </li>
              <li
                className={searchParams.get('type') === 'settings' ? 'current' : ''}
                onClick={() =>
                  setSearchParams({
                    type: 'settings'
                  })
                }
              >
                <SettingsIcon />
                <span>{t('Settings')}</span>
              </li>
              <li
                className={searchParams.get('type') === 'messages' ? 'current' : ''}
                onClick={() =>
                  setSearchParams({
                    type: 'messages'
                  })
                }
              >
                <MessagesIcon />
                <span>{t('Messages')}</span>
              </li>
            </ul>
            {/* TOLOVLAR */}
            {searchParams.get('type') === 'payments' && <Payments />}
            {/* TARIFLAR*/}
            {searchParams.get('type') === 'tariffs' && <Tariffs />}
            {/* Sozlamalar */}
            {searchParams.get('type') === 'settings' && <Settings userData={user} />}
            {/* XABAR    */}
            {searchParams.get('type') === 'messages' && <Messages />}
          </div>
        </div>
      </section>
    </MainLayout>
  )
}
