import React, { useContext, useState } from 'react'
import GetContainer from '../../../components/get-container'
import { MEDIA_URL, request } from '../../../helpers/request'
import { useTranslation } from 'react-i18next'

import DeviceCard from '../../../components/device-card'
import toast from 'react-hot-toast'
import PaginatedItems from '../../../components/pagination'
import moment from 'moment'
import { AuthContext } from '../../../providers/auth-provider'
import Success from '../../../components/notifications/Success'
import Error from '../../../components/notifications/Error'

const SET_TARIFF = async (id: number) => {
  const response = await request({
    url: 'dashboard/user/tariffs/' + id,
    method: 'GET'
  })

  return response?.data
}

function Tariffs() {
  const [termId, setTermId] = useState(1)
  const { t, i18n } = useTranslation()
  const [pageNumber, setPageNumber] = useState(1)
  const { refetchToken } = useContext(AuthContext)

  return (
    <div className='account-tab'>
      <div className='account-rate rate'>
        <div className='rate-card'>
          <div className='rate__subtitle'>{t('Our tariff plan')}</div>
          <GetContainer
            url='dashboard/user/tariffs/history'
            params={{
              perPage: 10,
              page: pageNumber
            }}
          >
            {({ data, refetch }) => (
              <>
                <div className='rate-table'>
                  <div className='rate-table__row rate-table__head'>
                    <div>{t('Purchased')}</div>
                    <div>{t('Tariff')}</div>
                    <div>{t('It ends')}</div>
                    <div>{t('Additional Information')}</div>
                  </div>
                  {data?.data?.map((item: any) => (
                    <div className='rate-table__row'>
                      <div>{moment(item.start_date)?.format('HH:mm DD.MM.YYYY')} </div>
                      <div>{item?.tariff?.name?.[i18n.language]}</div>
                      <div>{moment(item.end_date)?.format('HH:mm DD.MM.YYYY')} </div>
                      <div>{item.additional_info ? t('Connected') : t('Disconnected')}</div>
                    </div>
                  ))}
                </div>
                {data && <PaginatedItems total={data?.meta?.total} itemsPerPage={10} setPageNumber={setPageNumber} />}
              </>
            )}
          </GetContainer>
        </div>
        <div className='rate-card'>
          <div className='rate__subtitle'>{t('Devices')}</div>
          <div className='rate-devices'>
            <GetContainer url='dashboard/user/devices'>
              {({ data, refetch }) =>
                data?.data?.map((item: any) => <DeviceCard key={item?.id} item={item} refetch={refetch} />)
              }
            </GetContainer>
          </div>
        </div>
        <div className='rate-head'>
          <h2 className='rate__title section-title'>{t('Tariffs')}</h2>
          <GetContainer url='dashboard/user/terms'>
            {({ data }) => (
              <div className='rate-head__list'>
                {data?.data?.map((item: any) => (
                  <button onClick={() => setTermId(item?.id)} className={termId === item.id ? 'current' : ''}>
                    {item?.name?.[i18n.language]}
                  </button>
                ))}
              </div>
            )}
          </GetContainer>
        </div>
        <GetContainer url='dashboard/user/tariffs'>
          {({ data }) => (
            <div className='rate-wrap'>
              {data?.data
                ?.filter((item: any) => item.term_id?.id === termId)
                ?.map((el: any) => (
                  <div className='rate-item'>
                    <div
                      className='rate-item__card'
                      style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div className='rate-item__head'>
                        <div className='rate-item__wrap'>
                          <div className='rate-item__name'>{el?.name?.[i18n.language]}</div>
                          <div className='rate-item__text'>{t('What is included?')}</div>
                        </div>
                        <div className='rate-item__ico'>
                          <img src={MEDIA_URL + el?.photo} alt='ico' />
                        </div>
                      </div>
                      <ul className='rate-item__list'>
                        {el?.advantages?.map((val: any) => (
                          <li className={val.status ? 'active' : ''}>
                            <div className='rate-item__check' />
                            <p>{val?.name?.[i18n.language]}</p>
                          </li>
                        ))}
                      </ul>
                      <div className='rate-item__price'>{el.price} UZS</div>
                      <button
                        onClick={() =>
                          SET_TARIFF(el?.id)
                            .then(res => {
                              toast.custom(tr => <Success text={t(res.message)} onClose={() => toast.dismiss(tr.id)} />)
                              refetchToken()
                            })
                            .catch(e =>
                              toast.custom(tr => (
                                <Error
                                  text={t(e?.response?.data?.message || e.message)}
                                  onClose={() => toast.dismiss(tr.id)}
                                />
                              ))
                            )
                        }
                        className='rate-item__btn btn'
                      >
                        {t('Purchase')}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </GetContainer>
      </div>
    </div>
  )
}

export default Tariffs
