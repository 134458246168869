import axios from 'axios'
import { toast } from 'react-hot-toast'
// import { toast } from 'react-hot-toast'
// import i18n from './i18n'

const production = false

export const API_URL = production ? process.env.REACT_APP_API_URL || '' : 'https://api.gippokamp.uz/api/v1'
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL || 'https://api.gippokamp.uz'
export const SITE_URL = process.env.REACT_APP_SITE_URL || 'https://tezparcel.uz/'
export const WS_URL = production ? process.env.REACT_APP_WS_URL || '' : 'https://api.gippokamp.uz/api/v1/'

const client = axios.create({ baseURL: API_URL })
export const request = ({ ...options }) => {
  const auth = window.localStorage.getItem('accessToken') ? `Bearer ${window.localStorage.getItem('accessToken')}` : ''
  client.defaults.headers.common.Authorization = auth
  client.defaults.headers.common.Accept = 'multipart/form-data'

  const onSuccess = (response: any) => {
    if (response?.data?.message === 'Device limited') {
      const redirect = window.location.pathname
      window.location.href = `/sign-in?redirect=${redirect}`
      return
    } else if (response?.data?.message === 'Device limited') {
      const redirect = window.location.pathname
      window.location.href = `/send-code?redirect=${redirect}`
      return
    }
    return response
  }
  const onError = (error: any) => {
    const redirect = window.location.pathname
    if (error.message === 'Request failed with status code 401') {
      if (window.location.pathname !== '/sign-in') {
        window.location.href = `/sign-in?redirect=${redirect}`
      } else {
        window.location.href = `/sign-in`
      }
    } else {
      //   toast.error(`${error.response?.data?.message || error.message}`)
    }

    return Promise.reject(error)
  }

  return client(options).then(onSuccess).catch(onError)
}
